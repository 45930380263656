import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Orderform from "../components/orderform";

const Eula = () => (
  <Layout>
    <SEO title="TERMS" />
    <Orderform />
  </Layout>
);

export default Eula;
